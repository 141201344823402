import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import { MdHome } from "react-icons/md";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { storage } from "../../firebase/firebase-config";
import {
  bannerType,
  homeItems,
  levelOptions,
  membershipOptions,
} from "../utils/formData";
import Dragndrop from "./dragndrop/Dragndrop";
import Preview from "./dragndrop/Preview";
import { uploadTrading } from "../store/actions/items";

const MySwal = withReactContent(Swal);

const AddCover = ({ refresh }) => {
  const [value, setValue] = useState("education:news");
  const [files, setFiles] = useState([]);

  const [selectedMembership, setSelectedMembership] = useState("public");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const clearAddedItems = () => {
    setFiles([]);
  };

  useEffect(() => {
    // Clear added items when the component is loaded
    setFiles([]); // Clear the added files array
  }, []);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleMembershipChange = (event) => {
    setSelectedMembership(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      contentType: value,
      name: "",
      category: "",
      streamURL: "",
      clickMe: "",
      imageURL: "",
      isMembersOnly: false,
      isGoldPlan: false,
      description: "",
      videoURL: "",
      orderNumber: 0,
    },
    enableReinitialize: true,
    validationSchema: () => {
      const validationSchema = yup.object({
        name: yup.string("Enter the name").required("Required field"),
        // Add validation rules for common fields
      });

      return validationSchema;
    },
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  const submitForm = async (values) => {
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere agregar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const file = files[0];
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`items/academy/banners/${file.name}`);
        await imageRef.put(file);

        const imageURL = await imageRef.getDownloadURL();

        values.imageURL = imageURL;
        let members = false;
        let goldPlan = false;

        if (selectedMembership === "public") {
          members = false;
          goldPlan = false;
        } else if (selectedMembership === "members") {
          members = true;
          goldPlan = false;
        } else if (selectedMembership === "goldPlan") {
          members = false;
          goldPlan = true; // Set goldPlan to true for the "goldPlan" membership
        }
        const updatedData = {
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          clickMe: values.clickMe,
          streamURL: values.streamURL,
          videoURL: values.videoURL.includes("youtube")
            ? values.videoURL + "&"
            : values.videoURL,
          isMembersOnly: members,
          isGoldPlan: goldPlan,
          videoURL: values.videoURL ? values.videoURL + "&" : "",
          cdate: new Date(),
          orderNumber: values.orderNumber,
        };

        try {
          if (value === "education:news") {
            await uploadTrading(updatedData, "education:news");
          } else {
            await uploadTrading(updatedData, "trading:news");
          }

          MySwal.fire("Item Guardado!", "", "success");
          refresh();
          formik.resetForm();
          removeAll(); // Clear the files after submission
        } catch (error) {
          console.log(error);
          MySwal.fire("Hubo un error al guardar el item", "", "error");
        }
      }
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const removeAll = () => {
    setFiles([]);
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        item
        lg={8}
        sm={8}
        xs={12}
        sx={[styles.cardStyle, { minHeight: "65vh" }]}
        className=" add-media-card mb-4 "
      >
        <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-75">
          <Typography
            sx={styles.cardTitleStyle}
            variant="h6"
            className="center"
          >
            Agregar Banners en Academia
          </Typography>
          <Box sx={styles.innerCardStyle}>
            <TextField
              fullWidth
              id="contentType"
              name="contentType"
              select
              label="Seleccione"
              value={value}
              onChange={handleChange}
              helperText="Escoja la sección que desea cambiar"
              SelectProps={{
                MenuProps: {
                  style: {
                    maxWidth: 0,
                    maxHeight: 300,
                    position: "absolute",
                    getContentAnchorEl: () => null,
                  },
                  disableScrollLock: true,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdHome />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {bannerType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="name"
              name="name"
              label="Nombre"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              label="Descripción"
              type="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />

            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="clickMe"
              name="clickMe"
              label="URL de transmisión"
              value={formik.values.clickMe}
              onChange={formik.handleChange}
              error={formik.touched.clickMe && Boolean(formik.errors.clickMe)}
              helperText={formik.touched.clickMe && formik.errors.clickMe}
            />

            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="videoURL"
              name="videoURL"
              label="URL de Video"
              value={formik.values.videoURL}
              onChange={formik.handleChange}
              error={formik.touched.videoURL && Boolean(formik.errors.videoURL)}
              helperText={formik.touched.videoURL && formik.errors.videoURL}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="streamURL"
              name="streamURL"
              label="URL de transmisión"
              value={formik.values.streamURL}
              onChange={formik.handleChange}
              error={
                formik.touched.streamURL && Boolean(formik.errors.streamURL)
              }
              helperText={formik.touched.streamURL && formik.errors.streamURL}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              type="number"
              id="orderNumber"
              name="orderNumber"
              label="Numero Orden"
              value={formik.values.orderNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.orderNumber && Boolean(formik.errors.orderNumber)
              }
              helperText={
                formik.touched.orderNumber && formik.errors.orderNumber
              }
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="membership"
              name="membership"
              select
              label="Membresía"
              value={selectedMembership}
              onChange={handleMembershipChange}
            >
              {membershipOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </TextField>

            <Box item md={6} sx={styles.uploadBoxStyle}>
              {files.length === 0 ? (
                <div className=" center m-auto">
                  <Dragndrop onDrop={onDrop} accept={"image/*"} maxFiles={1} />
                </div>
              ) : (
                <Preview files={files} removeAll={removeAll} />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              <Button
                className="w-50"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Crear
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddCover;
