import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MdSignalCellular1Bar } from "react-icons/md";
import { putSignal } from "../../store/actions/signals";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material";
const MySwal = withReactContent(Swal);

const NotificationsEditModal = ({ onClose, open, item }) => {
  const theme = useTheme();
  //Formik
  const validationSchema = yup.object({
    title: yup.string("Ingrese el titulo").required("Campo Obligatorio"),
    text: yup
      .string("Ingrese el texto de la notificación")
      .required("Campo Obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      title: item.notification.title ?? "",
      body: item.notification.description ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
      alert(JSON.stringify(values, null, 2));
    },
  });
  const SubmitForm = (values) => {
    MySwal.fire({
      title: "Quiere enviar este notificación?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Confirm",
      cancelButtonText: "Back",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire("Notificación Enviada!", "", "success");
        console.log(values);
      } else if (result.isDenied) {
        MySwal.fire("Cambios no guardados", "", "info");
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1000 }}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,

          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Notificación
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={8} sm={12}>
            <Box sx={styles.cardStyle} className=" add-media-card mb-4 ">
              <form onSubmit={formik.handleSubmit} className="m-auto p-2">
                <Box sx={styles.innerCardStyle}>
                  <Box className="">
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="title"
                      name="title"
                      label="Nombre"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                      helperText={formik.touched.title && formik.errors.title}
                    />
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="text"
                      name="text"
                      label="Descripción"
                      type="text"
                      value={formik.values.text}
                      onChange={formik.handleChange}
                      error={formik.touched.text && Boolean(formik.errors.text)}
                      helperText={formik.touched.text && formik.errors.text}
                    />
                    {/* <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="itemId"
              name="itemId"
              label="Id de Item"
              type="text"
              value={formik.values.itemId}
              onChange={formik.handleChange}
              error={formik.touched.itemId && Boolean(formik.errors.itemId)}
              helperText={formik.touched.itemId && formik.errors.itemId}
            /> */}
                  </Box>

                  {/* <FormControl
            component="fieldset"
            sx={{
              marginLeft: "0px !important",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: "0px !important", flexDirection: "row" }}
              value="start"
              label="Activos"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheck}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
            />
          </FormControl>
          <FormHelperText sx={{ marginTop: "-24px !important" }}>
            Solo Usuarios Activos
          </FormHelperText> */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      className="w-50"
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Crear
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationsEditModal;
