import jwtDecode from "jwt-decode";

import React, { useCallback, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import FormControl from "@mui/material/FormControl";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dragndrop from "../dragndrop/Dragndrop";
import Preview from "../dragndrop/Preview";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  MdClose,
  MdNotificationImportant,
  MdSignalCellular1Bar,
} from "react-icons/md";
import {
  editSignal,
  postPushNotificationAll,
  putSignal,
} from "../../store/actions/signals";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, IconButton, useTheme } from "@mui/material";
import { storage } from "../../../firebase/firebase-config";
import { useSelector } from "react-redux";
import { getExpiredStatus, refreshToken } from "../../store/actions/auth";

const MySwal = withReactContent(Swal);

const signalMode = [
  {
    value: "buy",
    label: "Buy",
  },
  {
    value: "sell",
    label: "Sell",
  },
  {
    value: "buyMarket",
    label: "Buy Market",
  },
  {
    value: "sellMarket",
    label: "Sell Market",
  },
  {
    value: "buyLimit",
    label: "Buy Limit",
  },
  {
    value: "sellLimit",
    label: "Sell Limit",
  },
  {
    value: "buy Stop",
    label: "Buy Stop",
  },
  {
    value: "sellStop",
    label: "Sell Stop",
  },
];
const tiers = [
  {
    value: 2,
    label: "Basic",
  },
];
const tiersLuxe = [
  {
    value: 3,
    label: "Luxe",
  },
];
const status = [
  {
    value: "active",
    label: "Activa",
  },

  {
    value: "won",
    label: "Ganada",
  },
  {
    value: "lost",
    label: "Perdida",
  },
  {
    value: "closed",
    label: "Cerrada",
  },
  {
    value: "discarded",
    label: "Descartada",
  },
];
const SignalsEditModal = ({
  onClose,
  open,
  signal,
  refresh,
  category,
  users,
}) => {
  const theme = useTheme();
  const [images, setImages] = useState([]);
  const [ogImage, setOgImage] = useState("");
  const [files, setFiles] = useState([]);
  const { idToken } = useSelector((state) => state.auth);
  const [preloadedUsers, setPreloadedUsers] = useState([]);
  const [expired, setexpired] = useState(false);
  const signalType = [
    {
      value: category,
      label: category === "forex" ? "Forex" : "Sintéticos",
    },
  ];
  useEffect(() => {
    if (signal.picture) {
      setOgImage(signal.picture);
    }
  }, []);

  useEffect(() => {
    // Clear added items when the component is loaded
    setFiles([]);
    setImages([]); // Clear the added files array
  }, []);

  const handleRemoveImg = () => {
    formik.setFieldValue("picture", "");
    setOgImage(""); // Update the ogImage state to the original image URL
  };

  useEffect(() => {
    console.log(signal.category);
  }, []);
  const preLoadUserList = async (selectedTier) => {
    let filteredUsers;

    switch (selectedTier) {
      case 2:
        filteredUsers = users.filter(
          (user) => user.isActive && user.pushToken !== undefined
        );
        break;
      case 3:
        filteredUsers = users.filter(
          (user) => user.isGoldPlan && user.pushToken !== undefined
        );
        break;
      default:
        filteredUsers = [];
    }

    const pTokens = filteredUsers.map((user) => user.pushToken).filter(Boolean);

    setPreloadedUsers(pTokens);
  };

  const formik = useFormik({
    initialValues: {
      alias: signal.alias ?? "",
      signalType: category,
      updated: signal.updated ?? "",
      description: signal.description ?? "",
      type: signal.type ?? "",
      currency: signal.currency ?? "",
      entryPrice: signal.entryPrice ?? "",
      eta: signal.eta ?? "0",
      stopLoss: signal.stopLoss ?? "",
      tp1: signal.tp1 ?? "",
      tp2: signal.tp2 ?? "",
      tp3: signal.tp3 ?? "",
      tp4: signal.tp4 ?? "",
      tp5: signal.tp5 ?? "",
      tp6: signal.tp6 ?? "",
      picture: signal.picture ?? "",
      status: signal.status ?? "",
      tier: signal.tier ? signal.tier : category === "forex" ? 3 : 2,
      tp1Reached: signal.tp1Reached ?? false,
      tp2Reached: signal.tp2Reached ?? false,
      tp3Reached: signal.tp3Reached ?? false,
      tp4Reached: signal.tp4Reached ?? false,
      tp5Reached: signal.tp5Reached ?? false,
      tp6Reached: signal.tp6Reached ?? false,
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });

  useEffect(() => {
    if (signal) {
      // Set the default value of status based on the signal object
      formik.setFieldValue(
        "status",
        signal.status === "" ? "active" : signal.status
      );
    }
  }, [signal]);
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const SubmitForm = (values) => {
    let loadingTimer;

    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Esta seguro que quiere editar esta señal?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Editar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (images.length > 0) {
        const timestamp = Date.now();

        const file = images[0];
        const storageRef = storage.ref();
        const fileNameWithDate = `${file.name}_${timestamp}`;
        // Upload the new image inside the course folder
        const imageRef = storageRef.child(
          `images/items/signals/${fileNameWithDate}`
        );
        await imageRef.put(file);
        values.picture = await imageRef.getDownloadURL();
      } else {
        // If no new images are selected, keep the original imageURL
        values.picture = ogImage || "https://picsum.photos/200/300";
      }
      if (values.tier === 2) {
        values.isMembersOnly = true;
        values.isGoldPlan = false;
      } else if (values.tier === 3) {
        values.isMembersOnly = true;
        values.isGoldPlan = true;
      }
      const signalData = {
        id: signal.id,
        alias: values.alias,
        updated: values.updated,
        description: values.description,
        type: values.type,
        currency: values.currency,
        entryPrice: values.entryPrice,
        eta: values.eta,
        stopLoss: values.stopLoss,
        tp1: values.tp1,
        tp2: values.tp2,
        tp3: values.tp3,
        tp4: values.tp4,
        tp5: values.tp5,
        tp6: values.tp6,
        status: values.status === "active" ? "" : values.status,
        picture: values.picture,
        isGoldPlan: values.isGoldPlan,
        isMembersOnly: values.isMembersOnly,
        tier: values.tier,
        tp1Reached: values.tp1Reached,
        tp2Reached: values.tp2Reached,
        tp3Reached: values.tp3Reached,
        tp4Reached: values.tp4Reached,
        tp5Reached: values.tp5Reached,
        tp6Reached: values.tp6Reached,
      };
      const getStatusSignal = () => {
        switch (values.status) {
          case "active":
            switch (values.type) {
              case "buy":
                return "¡COMPRAR!";
              case "sell":
                return "¡VENDER!";
              case "":
                return "ACTIVA";
              default:
                return "ACTIVA";
            }
            break;
          case "pending":
            return "PENDIENTE";
          case "won":
            return "GANADA";
          case "lost":
            return "PERDIDA";
          case "break":
            return "BREAK EVEN";
          case "discarded":
            return "DESCARTADA";
          case "cancelled":
            return "CANCELADA";
          case "closed":
            return "CERRADA";
          case "custom":
            return "";
          default:
            return "PENDIENTE";
        }
      };

      const selectedTier = values.tier;
      const data = {
        title: `¡Actualización! ${values.currency}`,
        text: getStatusSignal(),
        type: category === "forex" ? "forex" : "sinthethics",
        audience: selectedTier === 2 ? "basic" : "luxe",
        pushTokens: preloadedUsers,
      };

      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: `Enviando a  usuarios de ${
            values.tier === 2 ? "Basic y Luxe" : "Luxe"
          }`,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            // Set a timer to close the Swal loading after 3.5 seconds
            loadingTimer = setTimeout(() => {
              MySwal.close();
              MySwal.fire("Notificación Enviada!", "", "success");
            }, 4100);
          },
          showConfirmButton: false,
        });

        try {
          await editSignal(signalData, values.signalType);
          const tokenToUse = isValidToken(idToken)
            ? idToken
            : await refreshToken();
          // const apiCallStartTime = performance.now();

          await postPushNotificationAll(data, tokenToUse);

          clearTimeout(loadingTimer);
          MySwal.close();

          onClose();
          refresh();
        } catch (error) {
          console.log(error);
          MySwal.close();
          refresh();
          MySwal.fire("Error al guardar los cambios", "", "error");
          onClose();
        }
      } else if (result.isDenied) {
        onClose();
      }
    });
  };
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  //Dropzone

  const onDrop = useCallback(
    (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [images]
  );
  const removeAll = () => {
    setImages([]);

    URL.revokeObjectURL(images[0].preview);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1000, ml: { xs: 0, md: 10 }, mt: 5 }}
      maxWidth={"sm"}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,

          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={8} sm={12}>
            <Box sx={{ position: "absolute", right: 5, top: 0 }}>
              <IconButton
                onClick={() => onClose()}
                sx={{ backgroundColor: theme.palette.error.main }}
              >
                <MdClose />
              </IconButton>
            </Box>
            <Box onSubmit={formik.handleSubmit} component={"form"}>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="center"
                columnGap={4}
              >
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    id="signalType"
                    name="signalType"
                    select
                    label="Seleccione"
                    value={formik.values.signalType}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.signalType &&
                      Boolean(formik.errors.signalType)
                    }
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        disableScrollLock: true,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MdSignalCellular1Bar />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  >
                    {signalType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  {formik.values.signalType === "forex" ? (
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      label="Seleccione"
                      id="tier"
                      name="tier"
                      select
                      value={formik.values.tier}
                      onChange={formik.handleChange}
                      helperText="Escoja la sección que desea cambiar"
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdNotificationImportant />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {tiersLuxe.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      label="Seleccione"
                      id="tier"
                      name="tier"
                      select
                      value={formik.values.tier}
                      onChange={formik.handleChange}
                      helperText="Escoja la sección que desea cambiar"
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdNotificationImportant />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {tiers.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",

                    marginBottom: "5px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "20px",
                      marginBottom: "5px",
                      width: "100%",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      sx={{
                        width: { md: "50%", xs: "100%", fontWeight: "800" },
                      }}
                      onClick={() => preLoadUserList(formik.values.tier)}
                    >
                      Precargar lista
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={12} container justifyContent={"center"}>
                  {preloadedUsers.length > 0 && (
                    <Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            fontSize: "1rem !important",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          Audiencia: {"  "}
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: "700 !important",
                            fontSize: "1rem !important",
                            ml: 0.51,
                          }}
                        >
                          {preloadedUsers.length}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  md={12}
                  lg={12}
                  columnGap={2}
                  justifyContent={"space-between"}
                >
                  <Grid item md={5.5}>
                    <TextField
                      fullWidth
                      id="type"
                      name="type"
                      select
                      label="Seleccione"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                        PopperProps: {
                          disablePortal: true,
                          placement: "bottom-start", // Adjust the placement as needed
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdSignalCellular1Bar />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    >
                      {signalMode.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={5.5} xs={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="status"
                      name="status"
                      select
                      label="Status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                        PopperProps: {
                          disablePortal: true,
                          placement: "bottom-start",
                        },
                      }}
                    >
                      {status.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={5.5} xs={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="alias"
                      name="alias"
                      label="Nombre"
                      value={formik.values.alias}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.alias && Boolean(formik.errors.alias)
                      }
                    />
                  </Grid>
                  <Grid item md={5.5} xs={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="currency"
                      name="currency"
                      label="Moneda"
                      value={formik.values.currency}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.currency &&
                        Boolean(formik.errors.currency)
                      }
                    />
                  </Grid>
                  <Grid item md={5.5} xs={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="stopLoss"
                      name="stopLoss"
                      label="Stop Loss"
                      type="text"
                      value={formik.values.stopLoss}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.stopLoss &&
                        Boolean(formik.errors.stopLoss)
                      }
                    />
                  </Grid>
                  <Grid item md={5.5} xs={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="entryPrice"
                      name="entryPrice"
                      label="Precio de Entrada"
                      type="entryPrice"
                      value={formik.values.entryPrice}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.entryPrice &&
                        Boolean(formik.errors.entryPrice)
                      }
                    />
                  </Grid>

                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp1"
                      name="tp1"
                      label="tp1"
                      type="text"
                      value={formik.values.tp1}
                      onChange={formik.handleChange}
                      error={formik.touched.tp4 && Boolean(formik.errors.tp1)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp1Reached}
                          onChange={formik.handleChange}
                          name="tp1Reached"
                          color="primary"
                        />
                      }
                      label="tp1 Alc"
                    />
                  </Grid>
                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp2"
                      name="tp2"
                      label="tp2"
                      type="text"
                      value={formik.values.tp2}
                      onChange={formik.handleChange}
                      error={formik.touched.tp2 && Boolean(formik.errors.tp2)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp2Reached}
                          onChange={formik.handleChange}
                          name="tp2Reached"
                          color="primary"
                        />
                      }
                      label="tp2 Alc"
                    />
                  </Grid>
                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp3"
                      name="tp3"
                      label="tp3"
                      type="text"
                      value={formik.values.tp3}
                      onChange={formik.handleChange}
                      error={formik.touched.tp3 && Boolean(formik.errors.tp3)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp3Reached}
                          onChange={formik.handleChange}
                          name="tp3Reached"
                          color="primary"
                        />
                      }
                      label="tp3 Alc"
                    />
                  </Grid>
                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp4"
                      name="tp4"
                      label="tp4"
                      type="text"
                      value={formik.values.tp4}
                      onChange={formik.handleChange}
                      error={formik.touched.tp4 && Boolean(formik.errors.tp4)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp4Reached}
                          onChange={formik.handleChange}
                          name="tp4Reached"
                          color="primary"
                        />
                      }
                      label="tp4 Alc"
                    />
                  </Grid>
                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp5"
                      name="tp5"
                      label="tp5"
                      type="text"
                      value={formik.values.tp5}
                      onChange={formik.handleChange}
                      error={formik.touched.tp5 && Boolean(formik.errors.tp5)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp5Reached}
                          onChange={formik.handleChange}
                          name="tp5Reached"
                          color="primary"
                        />
                      }
                      label="tp5 Alc"
                    />
                  </Grid>
                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp6"
                      name="tp6"
                      label="tp6"
                      type="text"
                      value={formik.values.tp6}
                      onChange={formik.handleChange}
                      error={formik.touched.tp6 && Boolean(formik.errors.tp6)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp6Reached}
                          onChange={formik.handleChange}
                          name="tp6Reached"
                          color="primary"
                        />
                      }
                      label="tp6 Alc"
                    />
                  </Grid>
                  {/* <Grid item md={5.5}>
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="updated"
                  name="updated"
                  label="Actualizar"
                  type="text"
                  value={formik.values.updated}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.updated && Boolean(formik.errors.updated)
                  }
                />
              </Grid> */}
                  <Grid item md={5.5} xs={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="eta"
                      name="eta"
                      label="Tiempo Estimado"
                      type="text"
                      value={formik.values.eta}
                      onChange={formik.handleChange}
                      error={formik.touched.eta && Boolean(formik.errors.eta)}
                    />
                  </Grid>
                  <Grid item md={5.5} xs={12}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="description"
                      name="description"
                      label="Descripción"
                      type="text"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    md={12}
                    lg={12}
                    columnGap={2}
                    my={2}
                    justifyContent={"center"}
                  >
                    {ogImage === "" ? (
                      <Box item md={6} sx={styles.uploadBoxStyle}>
                        {images.length === 0 ? (
                          <div className=" center m-auto">
                            <Dragndrop
                              onDrop={onDrop}
                              accept={"image/*"}
                              maxFiles={1}
                            />
                          </div>
                        ) : (
                          <Preview files={images} removeAll={removeAll} />
                        )}
                      </Box>
                    ) : (
                      <Box item md={6} sx={styles.uploadBoxStyle} mt={2}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={[
                              styles.deleteIcon,
                              { background: theme.palette.error.main },
                            ]}
                            onClick={() => handleRemoveImg()}
                          >
                            <HighlightOffIcon />
                          </Box>
                          <img
                            src={signal.picture}
                            alt="Original"
                            style={{
                              width: "auto",
                              maxHeight: "125px",
                              borderRadius: "18px",
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                paddingBottom: "10px",
              }}
            >
              <Button
                className="w-50"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                onClick={formik.handleSubmit}
                disabled={preloadedUsers.length === 0}
              >
                Editar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SignalsEditModal;
