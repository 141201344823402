import { auth, db } from "../../../firebase/firebase-config";
import { ADMIN_API_URL, API_URL } from "../../variables/globals";
import { types } from "../types/types";

export const getAllUsers = () => {
  return (dispatch) => {
    const users = [];
    dispatch(isLoading(true));
    db.collection("users")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          users.push({ id: item.id, ...item.data() })
        );
        dispatch(getUsers(users));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getAllAdmins = () => {
  return (dispatch) => {
    const users = [];
    dispatch(isLoading(true));
    db.collection("admins")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          users.push({ id: item.id, ...item.data() })
        );
        dispatch(getAdmins(users));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const checkIfUserExists = async (email) => {
  const querySnapshot = await db
    .collection("users")
    .where("email", "==", email)
    .get();
  return !querySnapshot.empty;
};

export const createUser = async (data, token) => {
  const url = ADMIN_API_URL + "/createUser";

  const body = {
    email: data.email,
    name: data.name,
    lastname: data.lastname ?? "",
    lastname2: data.lastname2 ?? "",
    phone: data.phone ?? "",
    city: data.city || "",
    country: data.country || "",
    tier: data.tier ?? 2,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };
  // console.log("body : ", JSON.stringify(body));
  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const createAdmin = async (data, token) => {
  const url = API_URL + "/admins/manager";

  const body = {
    email: data.email,
    adminClaim: data.adminClaim, // superadmin / admin
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const deleteAdmin = async (data, token) => {
  const url = API_URL + "/admins/manager";

  const body = {
    email: data.email,
  };

  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const editUser = async (data) => {
  const body = {
    ...data,
  };

  try {
    // await user.updateEmail(body.email);

    await db.collection("users").doc(data.id).set(body, { merge: true });

    console.log(data, "User document and authentication updated successfully!");
  } catch (error) {
    console.error("Error updating user document and authentication:", error);
  }
};

export const deactivateUser = async (uid) => {
  try {
    // Update the user's document in Firestore to set isActive to false
    const userRef = db.collection("users").doc(uid);
    console.log(uid, userRef);
    await userRef.set(
      {
        isDisabled: true,
        isActive: false,
        isGoldPlan: false,
      },
      { merge: true }
    );

    console.log(`User with UID ${uid} has been deactivated.`);
  } catch (error) {
    console.error("Error deactivating user:", error);
  }
};

export const deleteUser = async (data, token) => {
  const url = ADMIN_API_URL + "/disableUser";

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    // console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const startListeningToUsers = () => {
  return (dispatch) => {
    dispatch(isLoading(true));

    const unsubscribe = db.collection("users").onSnapshot(
      (snapshot) => {
        const users = [];
        snapshot.docs.forEach((item) =>
          users.push({ id: item.id, ...item.data() })
        );

        dispatch(getUsers(users));
        dispatch(isLoading(false));
      },
      (error) => {
        console.error("Error listening to users:", error);
        dispatch(isLoading(false));
      }
    );

    // You can return the unsubscribe function if needed
    return unsubscribe;
  };
};
export const activateUser = async (uid) => {
  try {
    const userRef = db.collection("users").doc(uid);
    console.log(uid, userRef);
    await userRef.set(
      {
        isDisabled: false,
        isActive: false,
        isGoldPlan: false,
      },
      { merge: true }
    );

    console.log(`User with UID ${uid} has been activated.`);
  } catch (error) {
    console.error("Error deactivating user:", error);
  }
};

export const enableUser = async (data, token) => {
  const url = ADMIN_API_URL + "/deleteUser";

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    // console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

// Nombre - Apellido- telefono- discord- id de cuenta de broker  tier es number
export const getUsers = (users) => ({
  type: types.getUsers,
  payload: {
    users,
  },
});
export const getAdmins = (admins) => ({
  type: types.getAdmins,
  payload: {
    admins,
  },
});
export const isLoading = (isLoading) => ({
  type: types.isLoadingUsers,
  payload: {
    isLoading,
  },
});
