import React, { useCallback, useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useField, useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import { daysOfTheWeek } from "../forms/daysOfTheWeek";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { uploadScheduleEvent } from "../../store/actions/items";
import { uploadEvent } from "../../store/actions/schedules";
import { getExpiredStatus } from "../../store/actions/auth";

const MySwal = withReactContent(Swal);

export default function AddSchedulesDynamic({ scheduleName }) {
  const [disabled, setDisabled] = useState({});

  const [selectedDays, setSelectedDays] = useState([daysOfTheWeek]);
  const [checked, setChecked] = useState(false);
  const [expired, setexpired] = useState(false);
  const handleSelectedDay = (event) => {
    const selectedDay = event.target.name;
    const isChecked = event.target.checked;

    // Update the formik values based on the selected day
    if (isChecked) {
      formik.setValues({
        ...formik.values,
        days: [...formik.values.days, selectedDay], // Add selected day to the array
      });
    } else {
      formik.setValues({
        ...formik.values,
        days: formik.values.days.filter((day) => day !== selectedDay), // Remove unselected day from the array
      });
    }
  };

  const handleSelectedTime = (selectedTime, selectedDay) => {
    const hour = selectedTime ? selectedTime.format("HH:mm:ss") : "";

    // Check if the selected day already exists in the hours array
    const existingDayIndex = formik.values.hours.findIndex(
      (hour) => hour.day === selectedDay
    );

    // Create a new hour object with the selected day and formatted hour
    const hourObject = { day: selectedDay, hour };

    // Update the formik values based on the selected time
    if (existingDayIndex !== -1) {
      // If the day already exists, update the hour object
      const updatedHours = [...formik.values.hours];
      updatedHours[existingDayIndex] = hourObject;

      formik.setValues({
        ...formik.values,
        hours: updatedHours,
      });
    } else {
      // If the day does not exist, add the hour object to the array
      formik.setValues({
        ...formik.values,
        hours: [...formik.values.hours, hourObject],
      });
    }
  };

  const handleDefaultTime = (selectedDay) => {
    // Check if the selected day already exists in the hours array
    const existingDayIndex = formik.values.hours.findIndex(
      (hour) => hour.day === selectedDay
    );

    // Create a new hour object with the selected day and an empty hour value
    const hourObject = { day: selectedDay, hour: "" };

    // Update the formik values with the default hour object
    if (existingDayIndex !== -1) {
      // If the day already exists, update the hour object
      const updatedHours = [...formik.values.hours];
      updatedHours[existingDayIndex] = hourObject;

      formik.setValues({
        ...formik.values,
        hours: updatedHours,
      });
    } else {
      // If the day does not exist, add the default hour object to the array
      formik.setValues({
        ...formik.values,
        hours: [...formik.values.hours, hourObject],
      });
    }
  };

  function handleDisabled(id) {
    setDisabled((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const [selectedHour, setSelectedHour] = useState(false);

  const validationSchema = yup.object({
    title: yup.string().required("Campo Obligatorio"),
    subtitle: yup.string().required("Campo Obligatorio"),
    link: yup.string().required("Campo Obligatorio"),
    days: yup.array().required("Debe seleccionar al menos un día"),
    hours: yup
      .array()
      .required("Debe seleccionar al menos una hora")
      .min(1, "Debe seleccionar al menos una hora"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      link: "",
      subtitle: "",
      title: "",
      days: [],
      hours: [],
    },
    validationSchema: validationSchema, // Add validation schema here

    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const SubmitForm = async (values) => {
    const dayNumbers = values.days.map((day) => dayMap[day]);
    const updatedValues = { ...values, days: dayNumbers };
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    try {
      const result = await MySwal.fire({
        title: "¿Confirmar?",
        text: "¿Está seguro que quiere agregar este evento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Agregar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        MySwal.fire("¡Evento guardado!", "", "success");

        await uploadEvent(updatedValues, scheduleName);

        console.log(updatedValues);
      }
    } catch (error) {
      MySwal.fire("Hubo un error al guardar el item", "", "error");
      console.log(error);
    }
  };

  const dayMap = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item md={8} sm={12} className="mb-4">
        <Box sx={styles.cardStyle} className=" add-media-card mb-4 ">
          <form onSubmit={formik.handleSubmit} className="m-auto p-2">
            <Typography
              sx={styles.cardTitleStyle}
              variant="h6"
              className="center"
            >
              Agregar Evento en Agenda
            </Typography>
            <Box sx={styles.innerCardStyle}>
              <Box className="">
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="title"
                  name="title"
                  label="Titulo"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="subtitle"
                  name="subtitle"
                  label="Subtítulo"
                  type="text"
                  value={formik.values.subtitle}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subtitle && Boolean(formik.errors.subtitle)
                  }
                  helperText={formik.touched.subtitle && formik.errors.subtitle}
                />
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="link"
                  name="link"
                  label="link"
                  type="text"
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  error={formik.touched.link && Boolean(formik.errors.link)}
                  helperText={formik.touched.link && formik.errors.link}
                />
                {daysOfTheWeek.map((day) => (
                  <Box
                    key={day.id}
                    sx={{ display: "flex", flexDirection: "row", mt: 2 }}
                  >
                    <Grid
                      item
                      xs={4}
                      sx={{ alignContent: "flex-start !important" }}
                    >
                      <div>
                        <input
                          id={day.id}
                          type="checkbox"
                          name={day.day.value}
                          checked={formik.values.days.includes(day.day.value)} // Check if the day is already selected
                          onChange={handleSelectedDay}
                          onClick={() => handleDisabled(day.id)}
                        />
                        <label htmlFor={day.name}>{day.name}</label>
                      </div>
                    </Grid>

                    <Grid item xs={4} sx={{ marginLeft: "auto !important" }}>
                      <TimePicker
                        defaultValue={dayjs("2022-04-17T15:30")}
                        onChange={(selectedTime) =>
                          handleSelectedTime(
                            selectedTime
                              ? selectedTime
                              : dayjs("2022-04-17T15:30"),
                            day.day.value
                          )
                        }
                        disabled={!formik.values.days.includes(day.day.value)} // Disable the TimePicker if the day is not selected
                      />
                    </Grid>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "20px",
                  marginBottom: "5px",
                }}
              >
                <Button
                  className="w-50"
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={formik.handleSubmit}
                  type="submit"
                >
                  Crear
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}
