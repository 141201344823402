import { Box, Container, useTheme, Alert, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import SideDrawer from "./SideDrawer";
import { getExpiredStatus } from "../store/actions/auth";

const SidebarLayout = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [firstUse, setFirstUse] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [expired, setexpired] = useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
    // console.log('opened ==', open);
  };
  const contentStyle = {
    marginLeft: open ? "0px" : "calc(64px + 1px)",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      ...(!open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    },
  };
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  return (
    <>
      <SideDrawer
        sidebarOpen={open}
        handleDrawerToggle={handleDrawerToggle}
        setOpen={setOpen}
      />
      <Container maxWidth="xxl" sx={{ mt: 2, mb: 4 }}>
        <div className="wrapper ">
          {expired && (
            <Alert
              variant="filled"
              severity="error"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "800" }}>
                Error con la facturación, contacte a soporte.
              </Typography>
            </Alert>
          )}
          <Outlet />
        </div>
      </Container>
    </>
  );
};

export default SidebarLayout;
