import jwtDecode from "jwt-decode";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFirebaseItem } from "../store/actions/items";
import { refreshToken } from "../store/actions/auth";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import MUIDataTable from "mui-datatables";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import CustomBodyWithSpinner from "../components/tables/CustomBodyWithSpinner";
import AddUsers from "../components/AddUsers.js";
import {
  activateUser,
  deactivateUser,
  deleteUser,
  enableUser,
  getAllUsers,
} from "../store/actions/users";
import UsersEditModal from "../components/modals/UsersEditModal";
import { useTheme } from "@mui/material";
import { IoRefreshOutline } from "react-icons/io5";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);
export default function UsersView() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const { idToken, user, isAdmin, isSuperAdmin, isAlmighty } = useSelector(
    (state) => state.auth
  );
  const [usersFromDb, setUsersFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(true);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // useEffect(() => {
  //   console.log(isSuperAdmin);
  // }, [isSuperAdmin]);

  const getUsersFromFB = useCallback(() => {
    try {
      setloading(true);
      dispatch(getAllUsers());
      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        setloading(false);
      }, 1000);
      console.log(error);
    }
  }, [users]);

  // console.log(user.uid);
  useEffect(() => {
    if (isFirstUse.current) {
      getUsersFromFB();
      isFirstUse.current = false;
    }
  }, [getUsersFromFB]);
  const handleRefresh = () => {
    getUsersFromFB();
  };
  const handleOpenEditModal = (item) => {
    setSelectedItem(item);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setEditModalOpen(false);
  };
  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];

  const BodyComponent = useMemo(
    () => (tableProps) =>
      <CustomBodyWithSpinner loading={loading} {...tableProps} />,
    [loading]
  );

  const columns = [
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: false,
      },
    },
    {
      name: "lastname",
      label: "Apellido",
      options: {
        filter: false,
      },
    },
    {
      name: "lastname2",
      label: "Segundo Apellido",
      options: {
        filter: false,
      },
    },

    {
      name: "email",
      label: "Correo Electrónico",
      options: {
        filter: false,
      },
    },

    {
      name: "isActive",
      label: "Intermedio",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "isGoldPlan",
      label: "Avanzado",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "isDisabled",
      label: "Deshabilitado",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "nivel", // You can choose any name you like
      label: "Membresía",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta.rowData;
          const isActive = rowData[4];
          const isGoldPlan = rowData[5];
          const isDisabled = rowData[6];

          let tierLabel = "N/A";
          if (isDisabled) {
            tierLabel = "Desactivado";
          } else {
            if (!isActive && !isGoldPlan) {
              tierLabel = "Inactivo";
            } else if (isActive && !isGoldPlan) {
              tierLabel = "Basic";
            } else if (isGoldPlan) {
              tierLabel = "Luxe";
            }
          }

          // Add conditional styling for disabled users
          const cellStyle = isDisabled ? { color: "red" } : {};

          return (
            <span style={cellStyle}>
              {isDisabled ? "Deshabilitado" : tierLabel}
            </span>
          );
        },
      },
    },

    {
      name: "phone",
      label: "Teléfono",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "cdate",
      label: "Fecha de creación",
      options: {
        customBodyRender: (value) => {
          if (!value || !(value.seconds && value.nanoseconds)) {
            return ""; // If the value is not present or doesn't have seconds/nanoseconds, return an empty string
          }

          const date = new Date(
            value.seconds * 1000 + value.nanoseconds / 1000000
          );

          // Check if the date is valid
          if (isNaN(date.getTime())) {
            return ""; // If the date is not valid, return an empty string
          }

          const formattedDate = date.toLocaleDateString(); // Format the date as desired
          return formattedDate;
        },
        filter: false,
      },
    },

    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },

    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = users[dataIndex];

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            handleOpenEditModal(data);
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleEdit}
              disabled={data.email === "dev@northtec.io"}
            >
              <MdEdit />
            </IconButton>
          );
        },
        filter: false,
      },
    },
    {
      name: "delete",
      label: "Estado",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = users[dataIndex];
          const fullName = `${data?.name} ${data?.lastname}`;
          const id = data.id;

          const isDisabled = data.isDisabled;

          const handleActivate = () => {
            Swal.fire({
              title: "Activar Usuario",
              text: `¿Está seguro que desea activar a ${data.name} ${data.lastname} con correo ${data.email}?`,
              confirmButtonText: "Activar",
              showDenyButton: true,
              denyButtonText: "Cancelar",
            }).then(async (result) => {
              if (result.isConfirmed) {
                const body = {
                  uid: data.id,
                  disabled: false,
                };
                const loadingSwal = MySwal.fire({
                  title: "Guardando...",

                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    MySwal.showLoading();
                  },
                });
                try {
                  if (isValidToken(idToken)) {
                    const res = await deleteUser(body, idToken);
                    if (res.ok) {
                      await activateUser(id);
                      loadingSwal.close();
                      Swal.fire("Usuario activado", "", "success");
                      handleRefresh();
                    } else if (res.error) {
                      loadingSwal.close();
                      Swal.fire("Error al activar el usuario", "", "error");
                    }
                  } else {
                    const newToken = await refreshToken();

                    const res = await deleteUser(body, newToken);
                    if (res.ok) {
                      await activateUser(id);
                      loadingSwal.close();
                      Swal.fire("Usuario activado", "", "success");
                      handleRefresh();
                    } else if (res.error) {
                      loadingSwal.close();
                      Swal.fire("Error al activar el usuario", "", "error");
                    }
                  }
                } catch (error) {
                  console.error("Error deleting user:", error);
                  loadingSwal.close();
                  MySwal.fire(
                    "Hubo un error al activar el usuario",
                    "",
                    "error"
                  );
                }
              }
            });
          };

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: `¿Está seguro que desea desactivar a ${fullName} con correo ${data.email}?`,
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                const body = {
                  uid: data.id,
                  disabled: true,
                };
                const loadingSwal = MySwal.fire({
                  title: "Guardando...",

                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    MySwal.showLoading();
                  },
                });
                try {
                  if (isValidToken(idToken)) {
                    const res = await deleteUser(body, idToken);
                    if (res.ok) {
                      await deactivateUser(id);
                      loadingSwal.close();
                      Swal.fire("Usuario desactivado", "", "success");
                      handleRefresh();
                    } else if (res.error) {
                      loadingSwal.close();
                      Swal.fire("Error al eliminar el usuario", "", "error");
                    }
                  } else {
                    const newToken = await refreshToken();

                    const res = await deleteUser(body, newToken);
                    if (res.ok) {
                      await deactivateUser(id);
                      loadingSwal.close();
                      Swal.fire("Usuario eliminado", "", "success");
                      handleRefresh();
                    } else if (res.error) {
                      loadingSwal.close();
                      Swal.fire("Error al eliminar el usuario", "", "error");
                    }
                  }
                } catch (error) {
                  console.error("Error deleting user:", error);
                  loadingSwal.close();
                  MySwal.fire(
                    "Hubo un error al eliminar el usuario",
                    "",
                    "error"
                  );
                }
              }
            });
          };

          if (isDisabled) {
            // Display the activation button for disabled users
            return (
              <IconButton
                sx={{ backgroundColor: theme.palette.primary.main }}
                onClick={() => handleActivate(data.id)}
                disabled={
                  !isSuperAdmin &&
                  !isAlmighty &&
                  data.email === "dev@northtec.io"
                }
              >
                <MdAdd />
              </IconButton>
            );
          }
          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              disabled={
                (!isSuperAdmin && !isAlmighty) ||
                data.email === "dev@northtec.io"
              }
            >
              <MdDelete />
            </IconButton>
          );
        },

        filter: false,
      },
    },
  ];
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };

  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,

    onTableChange: () => setUsersFromDB(users),

    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };

  if (users === null) {
    return <div>loading</div>;
  }

  return (
    <div id="tab-content">
      <Container maxWidth="xl">
        <div className="divider"></div>
        <Box sx={{ mt: 6 }}>
          <AddUsers refresh={handleRefresh} isAdmin={isAdmin} />
        </Box>
        <div className="table-wrapper   mt-4">
          <MUIDataTable
            data={usersFromDb}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </div>
        {selectedItem && (
          <UsersEditModal
            item={selectedItem}
            open={editModalOpen}
            onClose={handleCloseEditModal}
            refresh={handleRefresh}
          />
        )}
      </Container>
    </div>
  );
}
