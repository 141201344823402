import React, { useCallback, useState, useEffect, useMemo } from "react";

import dayjs from "dayjs";
import {
  InputAdornment,
  MenuItem,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import { styles } from "../../../assets/styles/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { editUser } from "../../store/actions/users";
import { useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { memberOptions } from "../../utils/formData";
import { MdNotificationImportant } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers";
import Colors from "../../../assets/styles/Colors";
import { fsTimestampCreador } from "../../../firebase/firebase-config";
import { getExpiredStatus } from "../../store/actions/auth";

const MySwal = withReactContent(Swal);

const UsersEditModal = ({ onClose, open, item, refresh }) => {
  const theme = useTheme();
  const [selectedTier, setSelectedTier] = useState(1);
  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [expired, setexpired] = useState(false);
  useEffect(() => {
    if (item.isGoldPlan) {
      formik.setFieldValue("tier", 3);
    } else {
      formik.setFieldValue("tier", 2);
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      name: item.name ?? "",
      lastname: item.lastname ?? "",
      lastname2: item.lastname2 ?? "",
      phone: item.phone ?? "",
      country: item.country ?? "",
      city: item.city ?? "",
      address: item.address ?? "",
      personalId: item.personalId ?? "",
      birthday: parsedDate,
      idType: item.idType ?? "01",
      isActive: item.isActive || false,
      isGoldPlan: item.isGoldplan || false,

      tier: 2,
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const SubmitForm = async (values) => {
    const timestampBirthday = fsTimestampCreador.fromDate(
      new Date(formik.values.birthday)
    );
    const selectedTier = values.tier;
    let active = false;
    let goldPlan = false;
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }
    if (selectedTier === 2) {
      active = true;
      goldPlan = false;
    } else if (selectedTier === 3) {
      active = true;
      goldPlan = true;
    }

    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere editar este usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Guardar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      const userData = {
        id: item.id,
        name: values.name || "",
        lastname: values.lastname || "",
        lastname2: values.lastname2 ?? "",
        phone: values.phone || "",
        country: values.country || "",
        city: values.city || "",
        address: values.address || "",
        birthday: timestampBirthday,
        idType: values.idType || "",
        personalId: values.personalId || "",
        isActive: active ?? false,
        isGoldPlan: goldPlan ?? false,
      };

      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });
        try {
          await editUser(userData);
          MySwal.fire("Cambios Guardados!", "", "success");

          refresh();
          onClose();
        } catch (error) {
          MySwal.fire("Hubo un error al guardar los cambios", "", "error");
          onClose();
        }
      } else if (result.isDenied) {
        onClose();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,
          marginTop: 100,
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
      sx={{ zIndex: 1251, width: "100%" }}
      fullWidth={true}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Usuario
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={12} sm={12}>
            <Box sx={styles.cardStyle} className=" add-media-card mb-4 ">
              <form onSubmit={formik.handleSubmit} className="m-auto  w-100">
                <Box sx={styles.innerCardStyle}>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    label="Nombre"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="lastname"
                    name="lastname"
                    type="text"
                    label="Apellido"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastname && Boolean(formik.errors.lastname)
                    }
                    helperText={
                      formik.touched.lastname && formik.errors.lastname
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="lastname2"
                    name="lastname2"
                    type="text"
                    label="Segundo Apellido"
                    value={formik.values.lastname2}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastname2 &&
                      Boolean(formik.errors.lastname2)
                    }
                    helperText={
                      formik.touched.lastname2 && formik.errors.lastname2
                    }
                  />

                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="phone"
                    name="phone"
                    type="phone"
                    label="Telêfono"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />

                  <Grid
                    item
                    md={12}
                    container
                    justifyContent={"space-between"}
                    mt={2}
                    columnGap={2}
                  >
                    <Grid item md={5.5}>
                      <TextField
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="phone"
                        name="phone"
                        type="phone"
                        label="Telêfono"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </Grid>
                    <Grid item md={5.5}>
                      <TextField
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="country"
                        name="country"
                        type="country"
                        label="País"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    container
                    justifyContent={"space-between"}
                    mt={2}
                    columnGap={0.5}
                  >
                    <Grid item md={5.5}>
                      <TextField
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="city"
                        name="city"
                        type="city"
                        label="Ciudad"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </Grid>
                    <Grid item md={5.5}>
                      <TextField
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="personalId"
                        name="personalId"
                        type="personalId"
                        label="Número Identificación"
                        value={formik.values.personalId}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.personalId)
                        }
                        helperText={
                          formik.touched.personalId && formik.errors.personalId
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    container
                    justifyContent={"space-between"}
                    mt={2}
                    columnGap={2}
                  ></Grid>

                  <Grid
                    item
                    md={12}
                    container
                    justifyContent={"space-between"}
                    mt={2}
                    columnGap={2}
                  >
                    <Grid item md={5.5}>
                      <TextField
                        margin="dense"
                        variant="standard"
                        fullWidth
                        id="address"
                        name="address"
                        type="address"
                        label="Dirección"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                      />
                    </Grid>
                    <Grid item md={5.5}>
                      <DatePicker
                        placeholder="Fecha de Nacimiento"
                        value={parsedDate}
                        label="Fecha de Nacimiento"
                        id="birthday"
                        name="birthday"
                        sx={{
                          width: "100%",

                          color: "black !important",
                          "& .MuiOutlinedInput-root": {
                            // backgroundColor: "#ececec !important",

                            boxShadow:
                              "inset 0 0 5px rgba(0, 0, 0, 0.2) !important",
                            "& fieldset": {
                              color: `${Colors.greyLight} !important`,
                            },
                            "&:hover fieldset": {
                              color: `${Colors.greyLight} !important`,
                            },
                            "&.Mui-focused fieldset": {
                              color: `${Colors.greyLight} !important`,
                            },
                            "&:disabled": {
                              color: `${Colors.greyLight} !important`,
                              backgroundColor: `${Colors.greyLight} !important`,
                              color: `${Colors.grey} !important`,
                            },
                          },
                          "& .MuiIconButton-root": {
                            color: `${Colors.greyLight} !important`,
                          },
                          "& .MuiInputBase-input": {
                            color: `${Colors.greyLight} !important`,
                          },
                          "& .MuiFormLabel-root": {
                            color: `${Colors.greyLight} !important`,
                            "&:disabled": {
                              color: `${Colors.grey} !important`,
                            },
                          },
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: `${Colors.greyLight} !important`,
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: `${Colors.greyLight} !important`,
                          },
                        }}
                        onChange={(newValue) => {
                          formik.setFieldValue("birthday", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={
                              formik.touched.birthday &&
                              Boolean(formik.errors.birthday)
                            }
                            helperText={
                              formik.touched.birthday && formik.errors.birthday
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    container
                    justifyContent={"space-between"}
                    mt={2}
                    columnGap={2}
                  >
                    <Grid item md={12}>
                      <TextField
                        fullWidth
                        label="Membresía"
                        id="tier"
                        name="tier"
                        onClick={(e) => e.preventDefault()}
                        select
                        value={formik.values.tier}
                        onChange={formik.handleChange}
                        autoFocus={false}
                        SelectProps={{
                          MenuProps: {
                            style: {
                              maxWidth: 0,
                              maxHeight: 300,

                              getContentAnchorEl: () => null,
                            },
                            disableScrollLock: true,
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MdNotificationImportant />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      >
                        {memberOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: 4,
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      className="w-100"
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      Guardar Cambios
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UsersEditModal;
