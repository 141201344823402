export const homeItems = [
  {
    value: "pro",
    label: "Pro Academy",
    fields: [
      "category",
      "clickMe",
      "imageURL",
      "name",
      "section",
      "level",
      "streamURL",
      "isMembersOnly",
      "isGoldPlan",
      "type",
      "description",
    ],
  },
  {
    value: "lab",
    label: "Power Lab",
    fields: [
      "category",
      "clickMe",
      "imageURL",
      "name",
      "section",
      "level",
      "streamURL",
      "isMembersOnly",
      "isGoldPlan",
      "type",
      "description",
    ],
  },
];
export const homeItemsMain = [
  {
    value: "recommended",
    label: "Recomendados",
    fields: [
      "category",
      "cdate",
      "description",
      "videoURL",
      "imageURL",
      "name",
      "clickMe",
      "streamURL",
      "tags",
    ],
  },
  {
    value: "events",
    label: "Eventos",
    fields: [
      "category",
      "cdate",
      "description",
      "clickMe",
      "videoURL",
      "imageURL",
      "name",
      "streamURL",
      "tags",
    ],
  },
  {
    value: "success",
    label: "Sistema de Éxito",
    fields: ["cdate", "description", "imageURL", "name", "tags"],
  },
];
export const levelOptions = [
  { value: "beginner", label: "Básico" },
  { value: "intermediate", label: "Intermedio" },
  { value: "advanced", label: "Avanzado" },
];

export const servicesOptions = [
  { value: "trading", label: "Comunidad Trading" },
  { value: "academy", label: "Academia" },
  { value: "market", label: "Mercado Financiero" },
  { value: "ai", label: "Inteligencia Artificial" },
];

export const membershipOptions = [
  { value: "members", label: "Basic" },
  { value: "goldPlan", label: "Luxe" },
];

export const memberOptions = [
  { value: 2, label: "Basic" },
  { value: 3, label: "Luxe" },
];

export const bannerType = [
  { value: "education:news", label: "Comunidad" },
  { value: "trading:news", label: "Trading" },
];

export const idTypes = [
  {
    value: "01",
    label: "Nacional",
    id: 0,
  },
  {
    value: "02",
    label: "Jurídica",
    id: 1,
  },
  {
    value: "03",
    label: "Dimex",
    id: 2,
  },
  {
    value: "04",
    label: "Nite",
    id: 2,
  },
];
