export const daysOfTheWeek = [
  {
    id: "1",
    name: "Lunes",
    day: {
      value: "monday",
      hours: [
        "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"

      ],
    },
    isChecked: false 
  },
  {
    id: "2",
    name: "Martes",
    day: {
      value: "tuesday",
      hours: [
        "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"

      ],
    },
  },
  {
    id: "3",
    name: "Miercoles",
    day: {
      value: "wednesday",
      hours: [
        "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"

      ],
    },
    isChecked: false 
  },
  {
    id: "4",
    name: "Jueves",
    day: {
      value: "thursday",
      hours: [
        "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"

      ],
    },
    isChecked: false 
  },
  {
    id: "5",
    name: " Viernes",
    day: {
      value: "friday",
      hours: [
        "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"

      ],
    },
    isChecked: false 
  },
  {
    id: "6",
    name: "Sabado",
    day: {
      value: "saturday",
      hours: [
        "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"

      ],
    },
    isChecked: false 
  },
  {
    id: "7",
    name: "Domingo",
    day: {
      value: "sunday",
      hours: [
         "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"

      ],
    },
    isChecked: false 
  },
];
