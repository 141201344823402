import React, { useCallback, useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useField, useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";
import { daysOfTheWeek } from "./forms/daysOfTheWeek";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { uploadScheduleEvent } from "../store/actions/items";
import { getExpiredStatus } from "../store/actions/auth";

const MySwal = withReactContent(Swal);

export default function AddSchedules() {
  const scheduleName = "main";
  const [selectedDays, setSelectedDays] = useState([]);
  const [events, setEvents] = useState([]);
  const [expired, setexpired] = useState(false);

  const handleSelectedDay = (event) => {
    const selectedDay = event.target.name;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedDays([...selectedDays, selectedDay]);
    } else {
      setSelectedDays(selectedDays.filter((day) => day !== selectedDay));
    }
  };

  const handleAddEvent = () => {
    setEvents([...events, { day: selectedDays[0], hours: [] }]);
  };

  const handleSelectedTime = (selectedTime) => {
    if (selectedDays.length === 0) {
      Swal.fire("Seleccione un día primero", "", "error");
      return;
    }

    const newEvents = [...events];
    const updatedEvent = newEvents.find(
      (event) => event.day === selectedDays[0]
    );

    if (updatedEvent) {
      updatedEvent.hours = [
        ...updatedEvent.hours,
        selectedTime.format("HH:mm:ss"),
      ];
    }

    setEvents(newEvents);
  };

  const validationSchema = yup.object({
    title: yup.string().required("Campo Obligatorio"),
    subtitle: yup.string().required("Campo Obligatorio"),
    link: yup.string().required("Campo Obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      link: "",
      subtitle: "",
      title: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
      console.log(events);
    },
  });
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const handleSubmit = async (values) => {
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    if (selectedDays.length === 0) {
      Swal.fire("Debe seleccionar al menos un día", "", "error");
      return;
    }

    if (events.length === 0) {
      Swal.fire("Debe seleccionar al menos una hora", "", "error");
      return;
    }

    const dayNumbers = selectedDays.map((day) => dayMap[day]);
    const updatedEvents = events.map((event) => ({
      ...event,
      day: dayNumbers[0], // For this example, we assume events are only for the first selected day
    }));
    const updatedValues = { ...values, days: dayNumbers, hours: updatedEvents };

    try {
      const result = await MySwal.fire({
        title: "¿Confirmar?",
        text: "¿Está seguro que quiere agregar este evento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Agregar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        MySwal.fire("¡Evento guardado!", "", "success");
        await uploadScheduleEvent(updatedValues, scheduleName);
        console.log(updatedValues);
      }
    } catch (error) {
      MySwal.fire("Hubo un error al guardar el item", "", "error");
      console.log(error);
    }
  };

  const dayMap = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item md={8} sm={12} className="mb-4">
        <Box sx={styles.cardStyle} className="add-media-card mb-4">
          <form onSubmit={formik.handleSubmit} className="m-auto p-2">
            <Typography
              sx={styles.cardTitleStyle}
              variant="h6"
              className="center"
            >
              Agregar Evento en Agenda
            </Typography>
            <Box sx={styles.innerCardStyle}>
              <Box className="">
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="title"
                  name="title"
                  label="Titulo"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="subtitle"
                  name="subtitle"
                  label="Subtítulo"
                  type="text"
                  value={formik.values.subtitle}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subtitle && Boolean(formik.errors.subtitle)
                  }
                  helperText={formik.touched.subtitle && formik.errors.subtitle}
                />
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="link"
                  name="link"
                  label="link"
                  type="text"
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  error={formik.touched.link && Boolean(formik.errors.link)}
                  helperText={formik.touched.link && formik.errors.link}
                />
                <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
                  {daysOfTheWeek.map((day) => (
                    <Grid item xs={4}>
                      <div>
                        <input
                          id={day.id}
                          type="checkbox"
                          name={day.day.value}
                          checked={selectedDays.includes(day.day.value)}
                          onChange={handleSelectedDay}
                        />
                        <label htmlFor={day.name}>{day.name}</label>
                      </div>
                    </Grid>
                  ))}
                </Box>
                {selectedDays.length > 0 && (
                  <Box sx={styles.innerCardStyle}>
                    <Typography variant="h6" className="center">
                      Seleccionar Horarios
                    </Typography>
                    <Box>
                      <Grid container spacing={2}>
                        {selectedDays.map((selectedDay, index) => (
                          <Grid item xs={6} key={index}>
                            <Typography variant="h6">{selectedDay}</Typography>
                            <TimePicker
                              defaultValue={dayjs("2022-04-17T15:30")}
                              onChange={(selectedTime) =>
                                handleSelectedTime(selectedTime, selectedDay)
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: "20px",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    className="w-50"
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Agregar Evento
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}
