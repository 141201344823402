import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdSearch, MdPhoto, MdRefresh, MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import { styles } from "../../../assets/styles/styles";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import AddSchedules from "../../components/AddSchedules";

import MUIDataTable from "mui-datatables";
import {
  callDeleteAlertSchedule,
  deleteSchedule,
  getMasterClassFB,
} from "../../store/actions/schedules";
import { useDispatch, useSelector } from "react-redux";
import CustomBodyWithSpinner from "../../components/tables/CustomBodyWithSpinner";

import { MdEdit } from "react-icons/md";
import ScheduleEditModal from "../../components/modals/ScheduleEditModal";
import { useTheme } from "@mui/material";
import Swal from "sweetalert2";
import AddSchedulesDynamic from "../../components/schedules/AddSchedulesDynamic";

export default function MasterclassView() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isSuperAdmin, isAlmighty } = useSelector((state) => state.auth);
  const { masterclass } = useSelector((state) => state.schedules);
  const [schedulesFromDB, setSchedulesFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(true);

  const [productValueIndex, setProductValueIndex] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const getSchedulesFromDB = useCallback(() => {
    try {
      setloading(true);
      dispatch(getMasterClassFB());
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  }, [masterclass]);
  useEffect(() => {
    if (isFirstUse.current) {
      getSchedulesFromDB();

      isFirstUse.current = false;
    } else {
      setSchedulesFromDB([masterclass]);
    }
  }, [getSchedulesFromDB, masterclass]);

  // useEffect(() => {
  //   console.log(masterclass);
  //   console.log(schedulesFromDB);
  // }, [masterclass, schedulesFromDB]);

  const BodyComponent = useMemo(
    () => (tableProps) =>
      <CustomBodyWithSpinner loading={loading} {...tableProps} />,
    [loading]
  );
  const refreshSchedules = () => {
    getSchedulesFromDB();
  };
  const handleOpenEditModal = (schedule) => {
    console.log("", schedule);
    setSelectedSchedule(schedule);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedSchedule(null);
    setEditModalOpen(false);
  };

  // Event handler to save the edited schedule
  const handleSaveEditedSchedule = (editedSchedule) => {
    // Logic to save the edited schedule
    console.log("Edited Schedule:", editedSchedule);

    // Close the modal
    handleCloseEditModal();
  };

  const initDeleteProduct = (elementsToDelete) => {
    const rows = elementsToDelete.data;

    rows.forEach((element) => {
      const index = element.dataIndex;
      const productToDelete = masterclass[index];
      dispatch(deleteSchedule(productToDelete.id, index));
    });
  };
  const translateDay = (day) => {
    // Map the English day names to translated versions
    const translations = {
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
      sunday: "Domingo",
    };

    // Return the translated day name if available, otherwise return the original day name
    return translations[day.toLowerCase()] || day;
  };
  const columns = [
    {
      name: "title",
      label: "Titulo",
    },
    {
      name: "subtitle",
      label: "Subtítulo",
    },

    {
      name: "id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "days",
      label: "Días",
      options: {
        display: false,
      },
    },
    {
      name: "hours",
      label: "Horas",
      options: {
        customBodyRender: (value, tableMeta) => {
          const dataIndex = tableMeta.rowIndex;
          const schedule = masterclass[dataIndex];

          if (schedule && schedule.hours && schedule.hours.length > 0) {
            const hoursText = schedule.hours
              .map((item) => `${translateDay(item.day)}: ${item.hour}`)
              .join(", ");
            return hoursText;
          }

          return null;
        },
      },
    },

    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const schedule = masterclass[dataIndex];

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            console.log(" edit button");
            handleOpenEditModal(schedule);
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleEdit}
            >
              <MdEdit />
            </IconButton>
          );
        },
      },
      filter: false,
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const schedule = masterclass[dataIndex];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar el curso?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  deleteSchedule(schedule.id, "masterclass");
                  refreshSchedules();
                  Swal.fire("Evento eliminado", "", "success");
                } catch (error) {
                  console.error("Error deleting schedule:", error);
                  Swal.fire("Error al eliminar el curso", "", "error");
                }
              }
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              // disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];
  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    onTableChange: () => setSchedulesFromDB(masterclass),
    onRowsDelete: (rowsToDelete) => {
      callDeleteAlertSchedule(
        rowsToDelete,
        initDeleteProduct,
        getSchedulesFromDB,
        "Evento eliminado"
      );

      return false;
    },
  };

  if (masterclass === null) {
    return <div>loading</div>;
  }

  return (
    <div id="tab-content">
      <Container maxWidth="xl" sx={{ p: "0px !important" }}>
        <div className="divider"></div>
        <Box sx={{ mt: 6 }}>
          <AddSchedulesDynamic scheduleName={"masterclass"} />
        </Box>
        <Box sx={styles.cardStyle} className="content theme-card">
          <div className="table-wrapper   mt-4">
            <MUIDataTable
              data={schedulesFromDB}
              columns={columns}
              options={options}
              components={{ TableBody: BodyComponent }}
            />
          </div>
        </Box>

        {/* Render the edit modal */}
        {selectedSchedule && (
          <ScheduleEditModal
            schedule={selectedSchedule}
            open={editModalOpen}
            onClose={handleCloseEditModal}
            refresh={refreshSchedules}
            onSave={handleSaveEditedSchedule}
            scheduleName={"masterclass"}
          />
        )}
      </Container>
    </div>
  );
}
