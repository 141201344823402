import React from "react";
import { MdSearch, MdPhoto, MdRefresh, MdConstruction } from "react-icons/md";
import { styles } from "../../assets/styles/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AddNotifications from "../components/AddNotifications";
import AddWeeklyNotifications from "../components/AddWeeklyNotifications";
import { Box, Typography } from "@mui/material";

export default function WeeklyNotificationsView() {
  const isActive = false;

  if (!isActive) {
    return (
      <div id="tab-content">
        <Container maxWidth="xl" sx={{ p: "0px !important" }}>
          <div className="divider"></div>

          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={8} sx={{ mt: 6 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <MdConstruction />
                <Typography ml={2}>Sección en construcción</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  return (
    <div id="tab-content">
      <Container maxWidth="xl" sx={{ p: "0px !important" }}>
        <div className="divider"></div>

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={8} sx={{ mt: 6 }}>
            <AddWeeklyNotifications />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
