import { useCallback, useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import withReactContent from "sweetalert2-react-content";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import {
  editScheduleEvent,
  uploadScheduleEvent,
} from "../../store/actions/items";
import { daysOfTheWeek } from "../forms/daysOfTheWeek";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { styles } from "../../../assets/styles/styles";
import { editEvent, editMasterclassEvent } from "../../store/actions/schedules";
import { getExpiredStatus } from "../../store/actions/auth";

const MySwal = withReactContent(Swal);

export default function ScheduleEditModal({
  schedule,
  open,
  onClose,
  refresh,
  scheduleName,
}) {
  const theme = useTheme();
  const [disabled, setDisabled] = useState({});

  const [expired, setexpired] = useState(false);

  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const handleSelectedDay = (selectedDay) => {
    formik.setValues((prevValues) => {
      const isChecked = !prevValues.days.includes(selectedDay);

      const updatedDays = isChecked
        ? [...prevValues.days, selectedDay] // Add selected day as a number to the array
        : prevValues.days.filter((day) => day !== selectedDay); // Remove unselected day from the array

      // Check if the selected day already exists in the hours array
      const existingDayIndex = prevValues.hours.findIndex(
        (hour) => hour.day === dayMapReverse[selectedDay]
      );

      // Create a new hour object with the selected day and hour value as "12:00:00"
      const hourObject = { day: dayMapReverse[selectedDay], hour: "03:30:00" };

      const updatedHours = isChecked
        ? existingDayIndex !== -1
          ? [...prevValues.hours] // If the day already exists, keep the existing hours
          : [...prevValues.hours, hourObject] // If the day does not exist, add the hour object
        : prevValues.hours.filter(
            (hour) => hour.day !== dayMapReverse[selectedDay]
          ); // Remove hours for the unselected day

      return {
        ...prevValues,
        days: updatedDays,
        hours: updatedHours,
      };
    });
  };

  const handleSelectedTime = (selectedTime, selectedDay) => {
    const hour = selectedTime ? selectedTime.format("HH:mm:ss") : "";

    // Check if the selected day already exists in the hours array
    const existingDayIndex = formik.values.hours.findIndex(
      (hour) => hour.day === selectedDay
    );

    // Create a new hour object with the selected day name and formatted hour
    const hourObject = { day: selectedDay, hour };

    // Update the formik values based on the selected time
    if (existingDayIndex !== -1) {
      // If the day already exists, update the hour object
      const updatedHours = [...formik.values.hours];
      updatedHours[existingDayIndex] = hourObject;

      formik.setValues({
        ...formik.values,
        hours: updatedHours,
      });
    } else {
      // If the day does not exist, add the hour object to the array
      formik.setValues({
        ...formik.values,
        hours: [...formik.values.hours, hourObject],
      });
    }
  };

  const handleDisabled = (id) => {
    setDisabled((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const validationSchema = yup.object({
    title: yup.string().required("Campo Obligatorio"),
    subtitle: yup.string().required("Campo Obligatorio"),
    link: yup.string().required("Campo Obligatorio"),
    days: yup.array().required("Debe seleccionar al menos un día"),
    hours: yup
      .array()
      .required("Debe seleccionar al menos una hora")
      .min(1, "Debe seleccionar al menos una hora"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: schedule.title || "",
      subtitle: schedule.subtitle || "",
      link: schedule.link || "",
      days: schedule.days || [],
      hours: schedule.hours || [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });

  const SubmitForm = async (values) => {
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    try {
      const MySwal = withReactContent(
        Swal.mixin({
          customClass: {
            popup: "my-swal-popup-class",
          },
        })
      );

      const result = await MySwal.fire({
        title: "¿Guardar Cambios?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Guardar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        console.log(schedule.id, values);
        await editEvent(schedule.id, values, scheduleName);
        MySwal.fire("Agenda Guardada!", "", "success");

        onClose();
        refresh();
      } else if (result.isDenied) {
        onClose();
      }
    } catch (error) {
      MySwal.fire("Hubo un error al guardar el evento", "", "info");
      console.log(error);
    }
  };
  const dayMapReverse = {
    0: "sunday",
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
  };
  const dayMap = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  return (
    <Dialog open={open} onClose={onClose} sx={{ zIndex: 1251 }}>
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Evento de Agenda
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={8} sm={12} className="mb-4">
            <Box className="add-media-card mb-4" padding={2}>
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="h6" align="center" gutterBottom>
                  Editar Evento en Agenda
                </Typography>
                <Box marginTop={2}>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="title"
                    name="title"
                    label="Titulo"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="subtitle"
                    name="subtitle"
                    label="Subtítulo"
                    value={formik.values.subtitle}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.subtitle && Boolean(formik.errors.subtitle)
                    }
                    helperText={
                      formik.touched.subtitle && formik.errors.subtitle
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="link"
                    name="link"
                    label="link"
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    error={formik.touched.link && Boolean(formik.errors.link)}
                    helperText={formik.touched.link && formik.errors.link}
                  />
                  {daysOfTheWeek.map((day) => (
                    <Box
                      key={day.id}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      marginTop={2}
                    >
                      {/* {console.log(
                        "Day:",
                        day.day.value,
                        "Schedules",
                        schedule.days
                      )} */}
                      <div key={day.id}>
                        <input
                          id={day.id}
                          type="checkbox"
                          name={day.day.value}
                          defaultChecked={
                            schedule.days.includes(dayMap[day.day.value]) ||
                            formik.values.days.includes(dayMap[day.day.value])
                          }
                          onClick={() =>
                            handleSelectedDay(dayMap[day.day.value])
                          }
                        />
                        <label htmlFor={day.name}>{day.name}</label>
                      </div>

                      <Box marginLeft="auto">
                        <TimePicker
                          defaultValue={
                            schedule.hours.find(
                              (hour) => hour.day === day.day.value
                            )?.hour
                              ? dayjs(
                                  `2022-04-17T${
                                    schedule.hours.find(
                                      (hour) => hour.day === day.day.value
                                    ).hour
                                  }`
                                )
                              : dayjs("2022-04-17T15:30") // Default time if no value found
                          }
                          onChange={(selectedTime) =>
                            handleSelectedTime(
                              selectedTime
                                ? selectedTime
                                : dayjs("2022-04-17T15:30"), // Default time if cleared
                              day.day.value
                            )
                          }
                          disabled={
                            !formik.values.days.includes(dayMap[day.day.value])
                          }
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop={3}
                >
                  <Button color="primary" variant="contained" type="submit">
                    Guardar
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
