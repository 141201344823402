import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Title from "./Title";

function preventDefault(event) {
  event.preventDefault();
}

const RecentSales = () => {
  return (
    <>
      <Title>Ultimas Ventas</Title>
      <Typography component="p" variant="h4">
        ₡6850
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        23 de Junio 2022
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        Enrique Díaz
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        Repartidor: Ariel Ortega
      </Typography> */}
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          Ver Orden
        </Link>
      </div>
    </>
  );
};

export default RecentSales;
