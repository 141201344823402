import jwtDecode from "jwt-decode";
import "sweetalert2/dist/sweetalert2.min.css";
import React, { useCallback, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  checkIfUserExists,
  createAdmin,
  createUser,
} from "../store/actions/users";

import { useSelector } from "react-redux";
import { getExpiredStatus, refreshToken } from "../store/actions/auth";

import { MdNotificationImportant } from "react-icons/md";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { idTypes, memberOptions } from "../utils/formData";
import { DatePicker } from "@mui/x-date-pickers";
import Colors from "../../assets/styles/Colors";
import { fsTimestampCreador } from "../../firebase/firebase-config";

const MySwal = withReactContent(Swal);

const AddAdmins = ({ refresh }) => {
  const { idToken } = useSelector((state) => state.auth);
  const [selectedTier, setSelectedTier] = useState(1);
  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [expired, setexpired] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string("Ingresa un correo electrónico")
      .email("Ingresa un correo electrónico válido")
      .test(
        "is-lowercase",
        "El email debe estar en minúsculas",
        (value) => value === value.toLowerCase()
      )
      .required("Campo Obligatorio"),
    adminClaim: yup
      .string("Seleccione el rol de administrador")
      .oneOf(["moderator", "admin", "superadmin"], "Seleccione un rol válido")
      .required("Campo Obligatorio"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",

      adminClaim: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      SubmitForm(values);
    },
  });
  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];
  const admins = [
    {
      value: "moderator",
      label: "Moderador",
    },

    {
      value: "admin",
      label: "Administrador",
    },
    {
      value: "superadmin",
      label: "Superadmin",
    },
  ];
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const resetForm = () => {
    formik.resetForm();
  };

  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const SubmitForm = async (values) => {
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere agregar este usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });

        const adminData = {
          email: values.email,

          adminClaim: values.adminClaim,
        };

        const userExists = await checkIfUserExists(values.email);
        if (!userExists) {
          MySwal.fire("Usuario no existe", "", "error");
        } else {
          try {
            if (isValidToken(idToken)) {
              const res = await createAdmin(adminData, idToken);
              if (res.ok) {
                MySwal.fire(
                  `Usuario creado con el correo ${values.email}  `,
                  "",
                  "success"
                );
              } else if (res.error.code === 11) {
                MySwal.fire("El correo ya existe", "", "error");
              }
            } else {
              const newToken = await refreshToken();
              const res = await createAdmin(adminData, newToken);
              if (res.ok) {
                MySwal.fire(
                  `Usuario creado con el correo ${values.email} `,
                  "",
                  "success"
                );
              } else if (res.error.code === 11) {
                MySwal.fire("El correo ya existe", "", "error");
              }
            }
          } catch (error) {
            console.error("Error creating user:", error);

            MySwal.fire("Hubo un error al guardar el usuario", "", "error");
          }
        }

        refresh();
        resetForm();
      } else if (result.dismiss === "cancel") {
        MySwal.fire("Cambios no guardados", "", "info");
        refresh();
      }
    });
  };
  const handleMembershipChange = (event) => {
    setSelectedMembership(event.target.value);
  };
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        sx={styles.cardStyle}
        className=" mb-4 "
      >
        <form onSubmit={formik.handleSubmit} className="m-auto p-2 ">
          <Typography
            sx={styles.cardTitleStyle}
            variant="h6"
            className="center"
          >
            Agregar Administrador
          </Typography>
          <Box sx={styles.innerCardStyle}>
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="email"
              name="email"
              type="email"
              label="Correo Electrónico"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} // Add onBlur event handler
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              fullWidth
              margin="dense"
              label="Seleccione"
              onClick={(e) => e.preventDefault()}
              id="adminClaim"
              name="adminClaim"
              select
              value={formik.values.adminClaim}
              onChange={formik.handleChange}
              SelectProps={{
                MenuProps: {
                  style: {
                    maxWidth: 0,
                    maxHeight: 300,

                    getContentAnchorEl: () => null,
                  },
                  disableScrollLock: true,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdNotificationImportant />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {admins.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              <Button
                className="w-50"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!formik.isValid}
              >
                Crear
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddAdmins;
