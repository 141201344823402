import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdSearch, MdPhoto, MdRefresh, MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import { styles } from "../../assets/styles/styles";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import {
  callDeleteAlert,
  callDeleteAlertItem,
  deleteItem,
  getAcademyItem,
  setAcademy,
  setEvents,
  setRecommended,
  setSuccess,
} from "../store/actions/items";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { MdEdit } from "react-icons/md";
import CustomBodyWithSpinner from "../components/tables/CustomBodyWithSpinner";
import { getAllItems } from "../store/actions/items";
import AddItems from "../components/AddItems";
import ItemsEditModal from "../components/modals/ItemsEditModal.js";
import { Tab, Tabs, useTheme } from "@mui/material";
import LiveEventsEditModal from "../components/modals/LiveEventsEditModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingScreen from "./LoadingScreen";

const MySwal = withReactContent(Swal);

export default function ItemsView() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isSuperAdmin, isAlmighty } = useSelector((state) => state.auth);
  const { recommended, items, success, events } = useSelector(
    (state) => state.items
  );
  const [itemsFromDB, setItemsFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [liveeditModalOpen, setLiveEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedLiveEvent, setSelectedLiveEvent] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [homeItems, setHomeItems] = useState([]);
  const [liveItems, setLiveItems] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(true);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getItemsFromFB = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(setRecommended());
      await dispatch(setEvents());
      await dispatch(setSuccess());

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getItemsFromFB();
      isFirstUse.current = false;
    } else if (
      recommended?.length > 0 &&
      success?.length > 0 &&
      events?.length > 0
    ) {
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [recommended, success, events, getItemsFromFB]);
  const refresh = () => {
    setRefreshing(true);

    getItemsFromFB();

    setRefreshing(false);
  };
  // useEffect(() => {
  //   console.log(events);
  // }, [events]);

  const handleOpenEditModal = async (itemId) => {
    const selectedItem = await getAcademyItem(itemId);
    setSelectedItem(selectedItem);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setSelectedLiveEvent(null);
    setEditModalOpen(false);
  };
  const handleCloseLiveEditModal = () => {
    setSelectedItem(null);
    setSelectedLiveEvent(null);
    setSelectedLiveEvent(null);
    setLiveEditModalOpen(false);
  };
  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (!dataAvailable) {
        return (
          <CustomBodyWithSpinner loading={dataAvailable} {...tableProps} />
        );
      }

      return <CustomBodyWithSpinner loading={loading} {...tableProps} />;
    },
    [loading, dataAvailable]
  );
  const initDeleteProduct = (elementsToDelete) => {
    const rows = elementsToDelete.data;

    rows.forEach((element) => {
      const index = element.dataIndex;
      const productToDelete = items[index];
      dispatch(deleteItem(productToDelete.id, index));
    });
  };
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      filter: false,
    },
    {
      name: "imageURL",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? <img src={value} alt="" width={"50px"} /> : "--";
        },
        filter: false,
      },
    },
    {
      name: "orderNumber",
      label: "Orden",
      filter: false,
    },
    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          const date = new Date(
            value.seconds * 1000 + value.nanoseconds / 1000000
          );
          const formattedDate = date.toLocaleDateString(); // Format the date as desired

          return formattedDate;
        },
      },
    },

    {
      name: "status",
      label: "Editar",
      options: {
        customBodyRender: (_, tableMeta) => {
          const { rowData } = tableMeta;

          const itemId = rowData[0];

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={() => handleOpenEditModal(itemId)}
            >
              <MdEdit />
            </IconButton>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const { rowData } = tableMeta;

          const itemId = rowData[0];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar este item?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteItem(itemId);
                  refresh();
                  Swal.fire("Item eliminado", "", "success");
                } catch (error) {
                  console.error("Error deleting item:", error);
                  Swal.fire("Error al eliminar el item", "", "error");
                }
              }
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              // disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];
  const columnsSuccess = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      filter: false,
    },
    {
      name: "imageURL",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? <img src={value} alt="" width={"50px"} /> : "--";
        },
        filter: false,
      },
    },
    {
      name: "orderNumber",
      label: "Orden",
      filter: false,
    },
    {
      name: "status",
      label: "Editar",
      options: {
        customBodyRender: (_, tableMeta) => {
          const { rowData } = tableMeta;

          const itemId = rowData[0];

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={() => handleOpenEditModal(itemId)}
            >
              <MdEdit />
            </IconButton>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const { rowData } = tableMeta;

          const itemId = rowData[0];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar este item?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteItem(itemId);
                  refresh();
                  Swal.fire("Item eliminado", "", "success");
                } catch (error) {
                  console.error("Error deleting item:", error);
                  Swal.fire("Error al eliminar el item", "", "error");
                }
              }
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              // disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];

  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    pagination: {
      rowsPerPage: "Filas:",
      displayRows: "/",
    },
    onTableChange: (action, tableState) => {
      if (
        action === "filterChange" ||
        action === "resetFilters" ||
        action === "search"
      ) {
        const filteredItems = tableState.displayData.filter(
          (_, index) =>
            tableState.selectedRows.data.findIndex(
              ({ index: dataIndex }) => dataIndex === index
            ) === -1
        );
        const filteredHomeItems = filteredItems.filter((item) =>
          item.data[0].includes("home")
        );
        setHomeItems(filteredHomeItems);
      }
    },

    onRowsDelete: (rowsToDelete) => {
      // Show the delete confirmation alert using SweetAlert2
      Swal.fire({
        title: "Alerta",
        text: "¿Está seguro que desea eliminar?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        showDenyButton: true,
        denyButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Handle the delete operation for each row
            for (const row of rowsToDelete.data) {
              const dataIndex = row.index;
              const data = homeItems[dataIndex];
              console.log(data);
              // Use an asynchronous function to delete the item
              await deleteItem(data.id, dataIndex);
            }

            refresh();
            // Show success message after successful deletion
            Swal.fire("Flyer eliminado", "", "success");

            // Trigger a refresh to update the data after deletion
          } catch (error) {
            console.error("Error deleting item:", error);
            // Show an error message if something goes wrong with the delete operation
            Swal.fire("Error al eliminar el item", "", "error");
          }
        } else if (result.isDenied) {
          // Handle the "Volver" button action (if needed)
          console.log("Volver button clicked");
        }
      });

      // Prevent the default behavior of the MUIDataTable
      return false;
    },
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div id="tab-content">
      <Container maxWidth="xl" sx={{ p: "0px !important" }}>
        <div className="divider"></div>

        <Box sx={{ mt: 6, mb: 6 }}>
          <AddItems refresh={refresh} />
        </Box>
        <Box sx={styles.cardStyle} className="content theme-card">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { backgroundColor: theme.palette.primary.main },
            }}
          >
            <Tab
              label="Recomendado"
              style={
                tabValue === 0
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />
            <Tab
              label="Eventos"
              style={
                tabValue === 1
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />
            <Tab
              label="Sistema de Exito"
              style={
                tabValue === 2
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />

            <div className="ml-auto mr-5  ">
              <IconButton color="success" onClick={() => refresh()}>
                <MdRefresh size={24} />
              </IconButton>
            </div>
          </Tabs>

          <div className="table-wrapper  ">
            {tabValue === 0 && (
              <MUIDataTable
                data={recommended}
                columns={columns}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )}
            {tabValue === 1 && (
              <MUIDataTable
                data={events}
                columns={columns}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )}
            {tabValue === 2 && (
              <MUIDataTable
                data={success}
                columns={columnsSuccess}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )}
          </div>
        </Box>
        {selectedItem !== null && (
          <ItemsEditModal
            refresh={refresh}
            data={selectedItem}
            open={editModalOpen}
            onClose={handleCloseEditModal}
            category={
              tabValue === 0
                ? "recommended"
                : tabValue === 1
                ? "events"
                : "success"
            }
          />
        )}
      </Container>
    </div>
  );
}
