import { db } from "../../firebase/firebase-config";

export const setSettings = async (collection) => {
  console.log("s");
  const userSettingsData = {
    academyActive: true,
    communityActive: true,
    communitySection: {
      sectionOne: true,
      sectionThree: true,
      sectionTwo: true,
    },
    schedulesActive: true,
    scheduleSections: {
      scheduleA: true,
      scheduleB: true,
    },
    signalsActive: true,
    signalSections: {
      first: true,
      second: true,
    },
    tradingActive: true,
    tradingSectionOne: {
      sectionActive: true,
      sectionOne: true,
      sectionOneLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
      sectionThree: true,
      sectionThreeLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
      sectionTwo: true,
      sectionTwoLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
    },
    tradingSectionTwo: {
      sectionActive: true,
      sectionOne: true,
      sectionOneLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
    },
    tradingSectionThree: {
      sectionActive: true,
      sectionOne: true,
      sectionOneLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
    },
  };

  await db
    .collection("products")
    .doc("otHcUHXhgTLsPxLBSfMB")
    .collection("privileges")
    .doc("contentSettings")

    .set(userSettingsData);
};
export const setModules = async () => {
  const userSettingsData = {
    academyActive: true,
    communityActive: false,
    communitySection: {
      sectionOne: true,
      sectionThree: true,
      sectionTwo: true,
    },
    scheduleSections: {
      scheduleA: true,
      scheduleB: true,
    },
    schedulesActive: true,
    signalSections: {
      first: true,
      second: true,
    },
    signalsActive: true,
    tradingActive: true,
    tradingSectionOne: {
      sectionActive: true,
      sectionOne: true,
      sectionOneLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
      sectionThree: true,
      sectionThreeLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
      sectionTwo: true,
      sectionTwoLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
    },
    tradingSectionTwo: {
      sectionActive: true,
      sectionOne: true,
      sectionOneLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
    },
    tradingSectionThree: {
      sectionActive: true,
      sectionOne: true,
      sectionOneLevels: {
        advanced: true,
        beginner: true,
        intermediate: true,
      },
    },
  };

  await db.collection("utils").doc("moduleStatus").set(userSettingsData);
};
